<template>
  <div>
    <Frame title="Configuración de subcontratación" :titleAction="titleAction" class="custom-frame">
      <Title
        title="Compañía contratista"
        :description="
          readOnly
            ? ''
            : 'Selecciona la compañía contratista y el periodo de fechas en el que ejecutará ese rol.'
        "
      />
      <Layout>
        <Column size="4">
          <Field label="Fecha de inicio">
            <Datepicker
              :required="true"
              v-model="model.EffectiveDate"
              rules="required"
              name="startDate"
              mode="date"
              ref="datepickStart"
              :readonly="modelToEdit !== null"
              @focusout.native="closeDatepicker"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Fecha de término">
            <Datepicker
              :required="false"
              v-model="model.EndDate"
              name="endDate"
              mode="date"
              ref="datepickEnd"
              :readonly="readOnly"
              @input="canSave"
              @focusout.native="closeDatepicker"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Unidad de negocio" ref="businessUnit">
            <MultiSelect
              :required="true"
              :options="businessUnitData"
              placeholder="Seleccione..."
              v-model="model.FKLocation"
              rules="required"
              :disabled="modelToEdit !== null"
            />
          </Field>
        </Column>
        <Column size="12">
          <Field label="Compañía contratista" ref="employerCompany">
            <MultiSelect
              :required="!readOnly"
              :options="companiesData"
              placeholder="Seleccione..."
              v-model="model.FKCompany"
              rules="required"
              :disabled="modelToEdit !== null"
            />
          </Field>
        </Column>
      </Layout>

      <Title
        title="Compañía contratante"
        :description="
          readOnly
            ? ''
            : 'Agrega compañías contratantes y el porcentaje de tiempo que tus colaboradores operarán en cada una.'
        "
      />
      <Layout>
        <Column size="8">
          <Field label="Compañía contratante">
            <Input
              style="visibility: hidden; height: 0"
              :required="!readOnly"
              v-model="filled"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="3">
          <Field label="Porcentaje de tiempo">
            <Input
              style="visibility: hidden; height: 0"
              :required="!readOnly"
              value="0"
              rules="required"
            />
          </Field>
        </Column>
      </Layout>
      <Layout v-for="(row, index) in employerCompanyFields" :key="index" style="margin-bottom: 3px">
        <Column size="8">
          <MultiSelect
            :options="row.options"
            placeholder="Seleccione..."
            v-model="model.LaborOutsourcingDetails[index].FKEmployerCompany"
            rules="required"
            :disabled="readOnly"
            @input="onInput(index)"
          />
        </Column>
        <Column size="3">
          <Input
            v-model="model.LaborOutsourcingDetails[index].Percentage"
            @keypress.enter="calculatePercentage(index)"
            @blur="calculatePercentage(index)"
            :readonly="readOnly"
            rules="required"
          />
        </Column>
        <Column size="1">
          <Button
            v-if="!readOnly"
            icon="table-row-remove"
            color="transparent"
            class="custom-button"
            @click="removeRow(index)"
          >
          </Button>
        </Column>
      </Layout>
      <Layout>
        <Column size="8">
          <Button
            v-if="!readOnly"
            icon="table-row-plus-after"
            color="transparent"
            class="custom-button"
            style="margin-bottom: 5px"
            @click="addRow"
          />
        </Column>
        <Column size="3" style="color: #5b89f2" v-if="!readOnly">
          {{ `Restante: ${percentageToComplete} %` }}
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
            color="terciary-outlined"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            v-if="!modelToEdit"
            color="secondary-outlined"
            :disabled="percentageToComplete != 0"
            @click="onSave(true)"
          >
            Guardar y nuevo
          </Button>
          <Button
            v-if="$can('EditOutsourcing', 'STM')"
            :color="modelToEdit && !model.LaborOutsourcingDetails.length ? 'quinary' : 'secondary'"
            :disabled="
              (percentageToComplete != 0 || (!dataChanged && modelToEdit)) &&
              (model.LaborOutsourcingDetails.length || !modelToEdit) &&
              !readOnly
            "
            @click="
              readOnly
                ? onEdit()
                : modelToEdit && !model.LaborOutsourcingDetails.length
                ? onDelete()
                : onSave(false)
            "
          >
            {{
              readOnly
                ? 'Editar'
                : modelToEdit
                ? !model.LaborOutsourcingDetails.length
                  ? 'Eliminar'
                  : 'Actualizar'
                : 'Guardar'
            }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OutsourcingsAddForm',
  props: {
    modelToEdit: Object,
    Employee: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        EffectiveDate: null,
        EndDate: null,
        FKTimEmployee: -1,
        FKLocation: null,
        FKCompany: null,
        setEndDate: false,
        LaborOutsourcingDetails: [
          {
            FKEmployerCompany: null,
            Percentage: '100.000',
          },
        ],
      },
      originalModel: {
        EffectiveDate: null,
        EndDate: null,
        FKTimEmployee: -1,
        FKLocation: null,
        FKCompany: null,
        setEndDate: false,
        LaborOutsourcingDetails: [
          {
            FKEmployerCompany: null,
            Percentage: '100.000',
          },
        ],
      },
      businessUnitData: [],
      companiesData: [],
      employerCompanyFields: [],
      percentageToComplete: '0.000',
      allEmployerCompanies: [],
      dataChanged: false,
      readOnly: false,
    };
  },
  methods: {
    onInput(index) {
      this.employerCompanyFields.forEach((element, index2) => {
        if (index !== index2) {
          const selected = [];
          this.model.LaborOutsourcingDetails.forEach((el, index3) => {
            if (index2 !== index3 && el.FKEmployerCompany != null) {
              selected.push(el.FKEmployerCompany);
            }
          });
          element.options = this.allEmployerCompanies.filter((el) => !selected.includes(el.id));
        }
      });
      this.canSave();
    },
    addRow() {
      this.employerCompanyFields.push({ options: [...this.allEmployerCompanies] });
      this.model.LaborOutsourcingDetails.push({
        FKEmployerCompany: null,
        Percentage: '0.001',
      });
      this.onInput(this.employerCompanyFields.length);
      this.calculateTotal();
    },
    removeRow(index) {
      this.model.LaborOutsourcingDetails[index].FKEmployerCompany = null;
      this.onInput(index);
      this.employerCompanyFields.splice(index, 1);
      this.model.LaborOutsourcingDetails.splice(index, 1);
      this.calculateTotal();
    },
    calculatePercentage(index) {
      const pattern = /^\d*[1-9]*\d(\.\d+)?$/;
      const value = this.model.LaborOutsourcingDetails[index].Percentage;

      const result = pattern.test(value);
      if (!result || parseFloat(value) === 0 || parseFloat(value) < 0.001) {
        this.model.LaborOutsourcingDetails[index].Percentage = '0.001';
      } else {
        this.model.LaborOutsourcingDetails[index].Percentage = parseFloat(value)
          .toFixed(3)
          .toString();
      }
      this.canSave();
      this.calculateTotal();
    },
    calculateTotal() {
      let total = 0.0;
      this.model.LaborOutsourcingDetails.forEach((item) => {
        total += parseFloat(item.Percentage);
      });
      this.percentageToComplete = (100 - total).toFixed(3).toString();
    },
    canSave() {
      if (!this.modelToEdit) return;
      const d1 = moment(this.model.EndDate).format('DD/MM/YYYY');
      const d2 = moment(this.modelToEdit.EndDate).format('DD/MM/YYYY');

      let current = '';
      this.model.LaborOutsourcingDetails.forEach((key) => {
        current += `${Object.entries(key).toString()},`;
      });

      let original = '';
      this.originalModel.LaborOutsourcingDetails.forEach((key) => {
        original += `${Object.entries(key).toString()},`;
      });

      this.dataChanged = d1 !== d2 || current !== original;
    },
    async onSave(clearAll) {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }

      this.save('outsourcings', this.model, 'stamp', 'LaborOutsourcingID')
        .then((data) => {
          if (data) {
            if (data.handleError) {
              this.showConfirm(
                '¿Desea reemplazar la configuración existente?',
                'Existe una Configuración de subcontratación vigente, a la que se le asignará fecha de término para permitir que esta configuración sea aplicada.',
                'danger',
                [
                  {
                    text: 'Cancelar',
                    callback: () => {},
                    props: { color: 'terciary-outlined' },
                  },
                  {
                    text: 'Reemplazar',
                    callback: () => {
                      this.model.setEndDate = true;
                      this.onSave(clearAll);
                    },
                    props: { color: 'quinary' },
                  },
                ]
              );
              return;
            }
            if (!clearAll) {
              this.$parent.close();
            } else {
              this.employerCompanyFields = [{ options: [...this.allEmployerCompanies] }];
              this.model = {
                EffectiveDate: null,
                EndDate: null,
                FKTimEmployee: -1,
                FKLocation: null,
                FKCompany: null,
                setEndDate: false,
                LaborOutsourcingDetails: [
                  {
                    FKEmployerCompany: null,
                    Percentage: '100.000',
                  },
                ],
              };
              this.percentageToComplete = '0.000';
              requestAnimationFrame(() => {
                this.$root.$children[0].$refs.validation.reset();
              });
            }
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperador, contacte con soporte.', 'warning');
          }
        });
    },
    onDelete() {
      this.$parent.$parent.$parent.deleteLaborOutsourcing(
        { id: this.model.LaborOutsourcingID },
        () => {
          this.$parent.close();
        }
      );
    },
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';
      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';
      this.employerCompanyFields = [];
      this.model = JSON.parse(JSON.stringify(this.originalModel));
      this.modelToEdit.LaborOutsourcingDetails.forEach((_, index) => {
        this.employerCompanyFields.push({ options: [...this.allEmployerCompanies] });
        this.onInput(index);
      });
      this.percentageToComplete = '0.000';
      this.$root.$emit('changeModeFrame', 'view');
    },
    getData() {
      this.getAll('outsourcings/catalogs/companylocation', '', 'stamp').then((catalogs) => {
        this.businessUnitData = catalogs.locationsCatalog;
        // console.log(this.businessUnitData);
        // this.businessUnitData.label = this.businessUnitData.locationCode.trim() + this.businessUnitData.label;
        this.companiesData = catalogs.companiesCatalog;
      });
      this.getAll('EmployerCompany', '', 'stamp').then((data) => {
        const labelData = [];
        data.forEach((el) => {
          labelData.push({
            id: el.id,
            label: `${el.code} - ${el.name}`,
          });
        });
        this.allEmployerCompanies = labelData;
        this.employerCompanyFields = [{ options: [...labelData] }];
        this.model.LaborOutsourcingDetails.forEach((_, index) => {
          if (index !== 0) {
            this.employerCompanyFields.push({ options: [...this.allEmployerCompanies] });
            this.onInput(this.employerCompanyFields.length);
          }
        });
      });
    },
    closeDatepicker() {
      // console.log(obj)
      // this.$refs.datepickStart.$refs.dateInput.closePopup()
      // this.$refs.datepickEnd.$refs.dateInput.closePopup()
    },
  },
  mounted() {
    this.readOnly = this.readonly;
    if (this.readonly) {
      this.$root.$emit('changeModeFrame', 'view');
    }
    if (this.modelToEdit) {
      this.titleAction = this.readonly ? 'Ver' : 'Editar';
      this.model = JSON.parse(JSON.stringify(this.modelToEdit));
      this.originalModel = JSON.parse(JSON.stringify(this.modelToEdit));
      if (this.model.LaborOutsourcingDetails.length) {
        for (let i = 0; i < this.model.LaborOutsourcingDetails.length; i++) {
          this.calculatePercentage(i);
          this.originalModel.LaborOutsourcingDetails[i].Percentage =
            this.model.LaborOutsourcingDetails[i].Percentage;
        }
      }
      this.dataChanged = false;
    } else {
      this.dataChanged = true;
    }

    if (this.Employee) {
      this.model.FKTimEmployee = this.Employee.id;
      this.originalModel.FKTimEmployee = this.Employee.id;
    }
    this.getData();
  },
  computed: {
    filled() {
      let canBeSaved = true;
      this.model.LaborOutsourcingDetails.forEach((element) => {
        if (element.FKEmployerCompany == null) {
          canBeSaved = false;
        }
      });
      if (canBeSaved) return 0;
      return null;
    },
  },
};
</script>

<style lang="scss">
.custom-frame {
  .pw-frame {
    padding-bottom: 0;
    &__content {
      margin-bottom: 0;
    }
  }
}
.custom-button {
  color: #969595;
  border: none;
  border-radius: 24px;
  &.is-transparent {
    background-color: #ebedf0;
  }
  &:hover {
    color: #2e76cf;
  }
}
</style>
