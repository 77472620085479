<template>
  <div>
    <Frame
      title="Configuración subcontratación"
      titleAction="Por compañía"
      :loading="loading"
      class="custom-frame"
    >
      <div slot="actions" v-if="$can('ViewOutsourcings', 'STM')">
        <span class="close-frame" @click="close">
          <i class="mdi mdi-close"></i>
        </span>
      </div>
      <div v-if="$can('ViewOutsourcings', 'STM')">
        <Grid
          :data="outsourcings"
          :columns="columns"
          :actions="actions"
          :showDetailRow="true"
          :customDetailRow="true"
          addText="Agregar conf. subcontratación"
          customEmptyText="Aún no ha sido agregada ninguna conf. de subcontratación, ¡agrega una!"
          class="custom-grid-outsourcings"
          :showAdd="$can('AddOutsourcing', 'STM')"
          @addNew="addNew"
          @reloadData="getOutsourcingsByCompany"
        >
          <template slot="detail" slot-scope="props">
            <td class="border_bot" />
            <!-- <td class="border_bot">
              <div class="title">Compañía contratante</div>
              <div
                v-for="(itemDet, index) in props.row.laborOutsourcingDetails"
                class="detailRow"
                :key="index">
                  <span v-html="`${itemDet.employerCompanyCode} - ${itemDet.employerCompanyName}`"></span>
              </div>
            </td>
            <td class="border_bot">
              <div class="title">Porcentaje de tiempo laborado</div>
              <div
                v-for="(itemDet, index) in props.row.laborOutsourcingDetails"
                class="detailRow"
                :key="index">
                  <span>{{ `${itemDet.percentageWorkedTime}.00%` }}</span>
              </div>
            </td> -->
            <td class="border_bot">
              <table class="detail_table" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="detail-title">Compañía contratante</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(itemDet, index) in props.row.laborOutsourcingDetails"
                    class="detailRow"
                    :key="index"
                  >
                    <td class="detail-row">
                      <span
                        v-html="`${itemDet.employerCompanyCode} - ${itemDet.employerCompanyName}`"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="border_bot">
              <table class="detail_table" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="detail-title">Porcentaje de tiempo laborados</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(itemDet, index) in props.row.laborOutsourcingDetails"
                    class="detailRow"
                    :key="index"
                  >
                    <td class="detail-row__percentage">
                      <span>{{
                        parseFloat(itemDet.percentageWorkedTime).toFixed(2).toString() + '%'
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" class="border_bot" />
          </template>
        </Grid>
      </div>
      <Layout v-else>
        <Column size="4" />
        <Column size="4">
          <ActionCard image="/assets/unauthorized.svg" text="Acceso denegado" />
        </Column>
      </Layout>
      <Modal :active.sync="openModal" class="modal-with-overflow">
        <OutsourcingsAddForm :modelToEdit="modelToEdit" :Employee="null" :readonly="readonly" />
      </Modal>
    </Frame>
  </div>
</template>

<script>
import moment from 'moment';
import OutsourcingsAddForm from './OutsourcingsAddForm.vue';

export default {
  name: 'EmployerCompany',
  components: {
    OutsourcingsAddForm,
  },
  data() {
    return {
      outsourcings: [],
      openModal: false,
      loading: false,
      modelToEdit: null,
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      columns: [
        {
          title: 'Compañía contratista',
          template: (item) => `${item.companyCode} - ${item.companyName}`,
          field: 'companyName',
          searchable: true,
        },
        {
          title: 'Unidad de negocio',
          template: (item) => `${item.businessUnitCode} - ${item.businessUnitDescription}`,
          field: 'businessUnitDescription',
          searchable: true,
        },
        {
          title: 'Fecha de inicio',
          field: 'effectiveDate',
          format: 'd',
        },
        {
          title: 'Fecha fin',
          template: (item) => this.getDate(item.endDate),
        },
      ],
      readonly: false,
    };
  },
  beforeMount() {
    if (this.$can('EditOutsourcing', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });
    if (this.$can('DeleteOutsourcing', 'STM'))
      this.actions.push({
        text: 'Eliminar',
        callback: this.deleteLaborOutsourcing,
      });
  },
  methods: {
    close() {
      if (this.$route.query && this.$route.query.callbackURI) {
        this.$router.push({ name: this.$route.query.callbackURI });
      } else {
        this.$router.push({ name: 'outsourcings' });
      }
    },
    onView(item) {
      this.readonly = true;
      this.modelToEdit = {
        LaborOutsourcingID: item.id,
        EffectiveDate: item.effectiveDate,
        EndDate: item.endDate,
        FKTimEmployee: -1,
        FKLocation: item.businessUnitID,
        FKCompany: item.companyID,
        LaborOutsourcingDetails: this.getDetails(item.laborOutsourcingDetails),
      };
      this.openModal = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.modelToEdit = {
        LaborOutsourcingID: item.id,
        EffectiveDate: item.effectiveDate,
        EndDate: item.endDate,
        FKTimEmployee: -1,
        FKLocation: item.businessUnitID,
        FKCompany: item.companyID,
        LaborOutsourcingDetails: this.getDetails(item.laborOutsourcingDetails),
      };
      this.openModal = true;
    },
    getDetails(arr) {
      const details = [];
      if (!arr) return details;
      arr.forEach((item) => {
        details.push({
          FKEmployerCompany: item.employerCompanyID,
          Percentage: item.percentageWorkedTime,
        });
      });
      return details;
    },
    getCompany(item) {
      return `${item.Company.Code} - ${item.Company.Name}`;
    },
    getDate(date) {
      const dateResult = moment(date).format('DD/MM/YYYY');
      if (!date || dateResult === 'Invalid date') return '-';
      return dateResult;
    },
    addNew() {
      this.readonly = false;
      this.modelToEdit = null;
      this.openModal = true;
    },
    deleteLaborOutsourcing(item, callback = () => {}) {
      this.deleteItem(
        'Outsourcings',
        item.id,
        '¿Desea eliminar la configuración?',
        'Al eliminar la configuración de subcontratación podrían ocurrir afectaciones a procesos relacionados.',
        'stamp',
        callback
      );
    },
    getOutsourcingsByCompany() {
      this.loading = true;

      this.getAll('Outsourcings', '', 'stamp').then((outsourcings) => {
        this.outsourcings = outsourcings;
        this.loading = false;
      });
    },
    templateActive(item) {
      return item.IsActive ? 'Sí' : 'No';
    },
    indexOf(array, obj) {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === obj) {
          return i;
        }
      }
      return -1;
    },
  },
  mounted() {
    this.getOutsourcingsByCompany();
  },
};
</script>

<style lang="scss">
.close-frame {
  cursor: pointer;
  font-size: 170%;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #a4afb7;
}
.border_bot {
  border-bottom: 1px solid #d8e3eb;
  font-size: 14px;
  color: #707070;
  .title {
    font-weight: 700;
    padding: 8px;
  }
  .detailRow {
    padding: 5px 8px;
  }
}
.custom-grid-outsourcings {
  .pw-datagrid {
    margin-top: 30px;
    table-layout: fixed;
    &__head {
      tr {
        th:nth-last-child(2) {
          text-align: center;
          width: 12%;
        }
        th:nth-last-child(3) {
          text-align: center;
          width: 12%;
        }
      }
      th {
        color: #333333;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        background-color: #f4f5f7;
        border-top: 1px solid #d8e3eb;
        border-bottom: 1px solid #d8e3eb;
        padding: 8px;
      }
    }
    &__body {
      .pw-datagrid__row {
        td:nth-last-child(2) {
          text-align: center;
        }
        td:nth-last-child(3) {
          text-align: center;
        }
        td {
          color: #333333;
          font-size: 16px;
          padding: 5px 8px;
          line-height: 19px;
          background-color: #fff;
          border-bottom: 1px solid #d8e3eb;
        }
      }
    }
  }
}

.detail-table {
  table-layout: fixed;
  text-align: left;
  border-spacing: 0;
  float: left;
}
.detail-title {
  font-weight: 700;
  padding: 10px;
}
.detail-row {
  padding: 5px 10px;
  &__percentage {
    text-align: right;
    padding: 5px 0px;
  }
}

.modal-with-overflow {
  .pw_modal__modal-content {
    overflow-y: scroll;
  }
}
</style>
